<template>
  <v-form
    ref="form"
    class="mb-4"
    data-cy="add-member-modal"
    @submit.prevent="onFormSubmit()"
  >
    <div
      class="personal-info-form"
    >
      <div
        v-t="'team.personalInfo'"
        class="v-card__title justify-center"
      />
      <v-text-field
        v-model="formData.name"
        :label="labels.name"
        :error="Boolean(errors.name)"
        :messages="errors.name"
        :rules="nameRules"
        data-cy="add-member-name"
      />
      <v-text-field
        v-model="formData.email"
        :label="labels.email"
        :error="Boolean(errors.email)"
        :messages="errors.email"
        :rules="emailRules"
        validate-on-blur
        data-cy="add-member-email"
      />
      <v-text-field
        v-model="formData.password"
        v-bind="dynamicPasswordProps"
        :label="labels.password"
        :error="passwordErrors.length > 0"
        :messages="passwordErrors"
        autocomplete="new-password"
        data-cy="add-member-password"
        @click:append="togglePasswordVisibility()"
        @blur="validatePasswordOnBlur"
      />
      <template v-if="passwordErrors.length > 0">
        <v-alert
          v-if="warning"
          dense
          type="warning"
          outlined
          class="core-element-info-message text-left"
        >
          {{ $t(`passwords.warnings.${warning}`) }}
        </v-alert>
        <v-alert
          v-if="suggestions.length > 0"
          color="info"
          dense
          :icon="infoIcon"
          outlined
          class="core-element-info-message text-left"
        >
          <template v-if="suggestions.length === 1">
            {{ $t(`passwords.suggestions.${suggestions[0]}`) }}
          </template>
          <template v-else>
            <h5>{{ $t('suggestions') }}</h5>
            <ul>
              <li
                v-for="suggestion in suggestions"
                :key="suggestion"
              >
                {{ $t(`passwords.suggestions.${suggestion}`) }}
              </li>
            </ul>
          </template>
        </v-alert>
      </template>
    </div>

    <v-row
      dense
      justify="center"
    >
      <v-col
        cols="12"
        sm="6"
      >
        <div
          v-t="'team.accessLevel'"
          class="v-card__title justify-center"
        />
        <v-row
          v-for="accessLevel in accessLevels"
          :key="accessLevel.name"
          justify="center"
          align="center"
        >
          <v-col
            cols="8"
            offset="2"
          >
            <v-switch
              v-model="formData.permissions[accessLevel.name]"
              :label="$t(`team.${camelize(accessLevel.name)}`)"
              inset
              :data-cy="`access-level-${accessLevel.name}`"
              class="mt-0 no-input-details pa-0"
              @change="e => onSwitchChange(accessLevel.name, e)"
            />
          </v-col>
          <v-col cols="2">
            <v-tooltip
              top
              class="mr-auto"
            >
              <template #activator="{ on }">
                <v-icon
                  color="primary"
                  dark
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span v-t="`team.${accessLevel.info}`" />
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="8"
            offset="2"
          >
            <v-switch
              input-value="true"
              :label="$t('team.support')"
              :disabled="true"
              inset
              class="mt-0 no-input-details pa-0"
            />
          </v-col>
          <v-col cols="2">
            <v-tooltip
              top
              class="mr-auto"
            >
              <template #activator="{ on }">
                <v-icon
                  color="primary"
                  dark
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span v-t="'team.supportAccessLevelInfo'" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <div
          v-t="'team.notifications'"
          class="v-card__title justify-center"
        />
        <v-row
          v-for="notification in availableNotifications"
          :key="notification.name"
          justify="center"
          align="center"
        >
          <v-col
            cols="8"
            :data-cy="notification.name"
          >
            <v-switch
              v-model="formData.notifications[notification.name]"
              :label="$t(`team.${camelize(notification.name)}`)"
              inset
              class="mt-0 no-input-details pa-0"
              @change="e => onSwitchChange(notification.name, e)"
            />
          </v-col>
          <v-col cols="2">
            <v-tooltip
              v-if="notification.info"
              top
              class="mr-auto"
            >
              <template #activator="{ on }">
                <v-icon
                  color="primary"
                  dark
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span v-t="`team.${notification.info}`" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <core-element-errors-message
      :show-errors="showApiErrors"
      :errors="errors"
    />
    <div class="text-center mt-3">
      <v-btn
        rounded
        :loading="loadingTeamMember"
        color="primary"
        data-cy="add-member-submit"
        type="submit"
      >
        {{ $t('submit') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import {
  mapValues,
  omit,
  flatMap,
  keys,
  pickBy,
  identity,
  isEmpty,
  compose,
  every,
  isEqual,
  values
} from 'lodash/fp'
import { isString } from 'lodash'
import { mapState, mapMutations } from 'vuex'
import {
  ADMIN_ACCESS_LEVEL,
  ACCESS_LEVELS_ENUM,
  STATIC_NOTIFICATIONS,
  ALL_NOTIFICATIONS, INFO_ICON
} from '../../../constants/app'
import { validateEmail } from '@/utils/validations'
import { MINIMAL_ALLOWED_PASSWORD_SCORE } from '@/constants/passwordPolicy'
const zxcvbn = () => import('@/utils/passwordValidator')

export default {
  props: {
    onSubmit: {
      type: Function,
      required: true
    },
    teamMember: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      accessLevels: ACCESS_LEVELS_ENUM,
      availableNotifications: STATIC_NOTIFICATIONS,
      passwordVisible: false,
      primarySwitchNames: [ALL_NOTIFICATIONS, ADMIN_ACCESS_LEVEL],
      formData: this.getDefaultFormData(),
      suggestions: [],
      warning: '',
      passwordErrors: [],
      infoIcon: INFO_ICON
    }
  },
  computed: {
    ...mapState('member', ['loadingTeamMember', 'errors', 'showApiErrors']),
    labels () {
      return {
        name: this.$t('fullName'),
        email: this.$t('email'),
        password: this.$t('password')
      }
    },
    accessPermissions () {
      return this.formData.permissions
    },
    dynamicPasswordProps () {
      return this.passwordVisible
        ? { appendIcon: 'mdi-eye', type: 'text' }
        : { appendIcon: 'mdi-eye-off', type: 'password' }
    },
    stringError () {
      return isString(this.errors) && this.errors
    },
    nameRules () {
      return [v => !!v || this.$i18n.t('errors.fieldIsRequired')]
    },
    emailRules () {
      return [
        v => !!v || this.$i18n.t('errors.fieldIsRequired'),
        v => validateEmail(v) || this.$i18n.t('errors.wrongEmailFormat')
      ]
    },
    computedFormData () {
      return Object.assign({}, this.formData)
    }
  },
  watch: {
    accessPermissions: {
      handler (val) {
        this.handlePermissionChange(val)
      },
      deep: true
    },
    computedFormData: {
      async handler (newVal, oldVal) {
        // clear password feedback when password is safe
        if (newVal && newVal.password && oldVal.password !== newVal.password) {
          const valid = await this.validatePassword(newVal.password)
          if (valid) {
            if (this.passwordErrors && Array.isArray(this.passwordErrors) && this.passwordErrors.length > 0) {
              this.passwordErrors = []
            }
            if (this.suggestions &&
              Array.isArray(this.suggestions) &&
              this.suggestions.length > 0) {
              this.suggestions = []
            }
            if (this.warning !== '') {
              this.warning = ''
            }
          }
        }
        // clear other field errors on input
        if (newVal && newVal.email !== oldVal.email && this.errors.email) {
          const newErrors = this.errors
          delete newErrors.email
          this.setErrors(newErrors)
        }
        if (newVal && newVal.name !== oldVal.name && this.errors.name) {
          const newErrors = this.errors
          delete newErrors.name
          this.setErrors(newErrors)
        }
      },
      deep: true
    }
  },
  mounted () {
    this.setErrors({})
    this.getDefaultFormData()

    if (this.teamMember) {
      this.handlePermissionChange(this.formData.permissions)

      const areAllNotificationsChecked = this.areAllValuesChecked(
        omit(this.primarySwitchNames, this.formData.notifications)
      )

      if (areAllNotificationsChecked) {
        this.formData.notifications[ALL_NOTIFICATIONS] = true
      }
    }
  },
  methods: {
    ...mapMutations('member', ['setErrors']),
    addMember () {
      this.onSubmit(this.formData)
    },
    togglePasswordVisibility () {
      this.passwordVisible = !this.passwordVisible
    },
    getDefaultFormData () {
      return this.teamMember
        ? {
            ...omit(['id', 'guid'], this.teamMember),
            permissions: ACCESS_LEVELS_ENUM.reduce((acc, val) => ({
              ...acc,
              [val.name]: this.teamMember.permissions.includes(val.name)
            }), {}),
            notifications: this.teamMember.notifications.reduce((acc, val) => ({
              ...acc,
              [val]: true
            }), {})
          }
        : {
            permissions: ACCESS_LEVELS_ENUM.reduce((acc, level) => ({
              ...acc,
              [level.name]: false
            }), {}),
            notifications: {}
          }
    },
    async onFormSubmit () {
      const formValid = this.$refs.form.validate()
      if (!this.teamMember && !this.formData.password) {
        this.passwordErrors = [this.$i18n.t('errors.fieldIsRequired')]
      }

      if (formValid && this.passwordErrors.length === 0) {
        const data = {
          ...this.formData,
          permissions: this.getCheckedKeys(this.formData.permissions),
          notifications: this.getCheckedKeys(omit(this.primarySwitchNames, this.formData.notifications))
        }

        this.onSubmit(data)
      }
    },
    onSwitchChange (inputName, val) {
      const isPrimarySwitch = this.primarySwitchNames.includes(inputName)
      const formKey = Object.prototype.hasOwnProperty.call(this.formData.permissions, inputName)
        ? 'permissions'
        : 'notifications'
      const primarySwitchKey = formKey === 'notifications' ? ALL_NOTIFICATIONS : null

      if (isPrimarySwitch) {
        this.formData[formKey] = mapValues(() => val, this.formData[formKey])
      } else if (!isPrimarySwitch && val === false) {
        const formData = {
          ...this.formData[formKey],
          [inputName]: false
        }

        if (primarySwitchKey) {
          formData[primarySwitchKey] = false
        }

        this.formData[formKey] = formData
      } else if (!isPrimarySwitch && primarySwitchKey) {
        const allActive = this.areAllValuesChecked(
          omit(this.primarySwitchNames, this.formData[formKey])
        )

        if (allActive) {
          this.formData[formKey] = {
            ...this.formData[formKey],
            [primarySwitchKey]: true
          }
        }
      }
    },
    handlePermissionChange (permissions) {
      const checkedPermissions = this.getCheckedKeys(permissions)
      const activeAccessLevels = ACCESS_LEVELS_ENUM.filter(acLevel => checkedPermissions.includes(acLevel.name))
      const availableNotifications = activeAccessLevels
        ? flatMap(acLevel => acLevel.availableNotifications, activeAccessLevels)
        : []

      this.availableNotifications = [
        ...STATIC_NOTIFICATIONS,
        ...availableNotifications
      ]

      this.formData.notifications = this.availableNotifications.reduce((acc, value) => ({
        ...acc,
        [value.name]: this.formData.notifications[value.name] || false
      }), {})
    },
    getCheckedKeys: compose(keys, pickBy(identity)),
    areAllValuesChecked: (formData) => {
      if (isEmpty(formData)) {
        return false
      }

      return compose(every(isEqual(true)), values)(formData)
    },
    camelize (string) {
      return this.$options.filters.camelize(string)
    },
    validatePassword (password) {
      return zxcvbn().then(zxcvbn => {
        const result = zxcvbn.passwordValidator(password)
        this.suggestions = result.feedback && result.feedback.suggestions
          ? result.feedback.suggestions.map(v => this.$options.filters.camelize(v))
          : []
        this.warning = result.feedback && result.feedback.warning
          ? this.$options.filters.camelize(result.feedback.warning)
          : ''
        return result.score >= MINIMAL_ALLOWED_PASSWORD_SCORE
      })
    },
    async validatePasswordOnBlur () {
      if (this.formData.password !== '') {
        const valid = await this.validatePassword(this.formData.password)
        if (!valid) {
          this.passwordErrors = [this.$i18n.t('errors.passwordIsTooEasy')]
        }
      // creating new team member
      } else if (!this.teamMember) {
        this.passwordErrors = [this.$i18n.t('errors.fieldIsRequired')]
        this.suggestions = []
        this.warning = ''
      } else {
        this.passwordErrors = []
        this.suggestions = []
        this.warning = ''
      }
    }
  }
}
</script>

<style>
.personal-info-form {
  margin: auto;
  max-width: 500px;
}
</style>
